import React, { Component } from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
// import { renderRoutes } from 'react-router-config';
import Loadable from 'react-loadable';
import './App.scss';


//const loading = () => <div className="animated fadeIn pt-3 text-center">Loading...</div>;
const loading = () => <div className="spinner-border text-info" style={{ width: '6rem', height: '6rem', margin: '20% 0 0 45% ' }} role="status">
  <span className="sr-only">Loading...</span>
</div>

// Containers
const DefaultLayout = Loadable({
  loader: () => import('./containers/DefaultLayout'),
  loading
});




class App extends Component {

  render() {
    return (
          <HashRouter>
            <Switch>
              <Route path="/" name="Home" component={DefaultLayout} />
            </Switch>
          </HashRouter>
        );
  }
}

export default App;
